import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
import { toDateStringVisual } from '@/utils/date';
export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  methods: {
    getDependencies(data) {
      return [];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData,
    formatBirthDateDate(customer) {
      return toDateStringVisual(customer.dateOfBirth);
    }
  }
};
